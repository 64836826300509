import React from 'react'
import Home from './Component/Home';
import {BrowserRouter,Routes, Route } from 'react-router-dom';
import Suscribe from './Component2/Suscribe';
import Contect from './Component2/Contect';
const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/suscribe" element={<Suscribe />} />
          <Route path='/contect'  element={<Contect/>} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
