import React from 'react'
import { morestories } from './MoreStoriesdata'

const MoreStories = () => {
    const Moredata=(data)=>{
        return(
        <div className="box flex py-3 hover:opacity-75 cursor-pointer ">
            <div className="left bg-black rounded-lg flex-1">
                <img src={data.image} alt="" className='opacity-80 rounded-lg w-full h-full object-cover'/>
            </div>
            <div className="right  px-3 flex-1 space-y-4 small:space-y-2">
                <span className='text-blue-500 text-lg font-semibold'>{data.title}</span>
                <p className='text-xl font-semibold capitalize pb-3 small:text-sm small:pb-2'>{data.para}</p>
                <span className='text-sm text-gray-500'>{data.writer} . AUG 2021</span>
            </div>
        </div>
        )
    }
  return (
  <div className="main bg-white w-full py-14">
    <div className="in w-3/4 m-auto ssm:w-full ssm:px-3">
        <h1 className='text-xl font-semibold'>More Stories</h1>
        <div className="items grid grid-cols-2  gap-5 pt-3 ssm:flex ssm:flex-col">
            {
              morestories.map(Moredata)  
            }
        </div>
    </div>
  </div>
  )
}

export default MoreStories