import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const Footer = () => {
    return (
        <>
            <div className="main bg-slate-800 w-full py-16 text-white">
                <div className="in flex justify-around sym:flex-col sym:px-3 sym:space-y-4">
                    <div className="address flex-col">
                        <h1 className='text-2xl font-semibold pb-3'>NEWS AKHBAR</h1>
                        <p >Azamgarh-223225</p>
                        <p>U.P. INDIA EAST</p>
                    </div>
                    <div className="pages list-none space-y-2">
                        <li><a href="">About</a></li>
                        <li><a href="">Home</a></li>
                        <li><a href="">Contect Us</a></li>
                        <li><a href="">News</a></li>
                    </div>
                    <div className="pages2 list-none space-y-2">
                        <li><a href="">Linencese</a></li>
                        <li><a href="">Styled Guide</a></li>
                        <li><a href="">Password</a></li>
                    </div>
                    <div className="icons space-y-2">
                        <h1 className='sym:text-lg'>Follow Me</h1>
                        <div className="icon space-x-2 cursor-pointer">
                           <a href="https://www.instagram.com/ab_star_04/"><InstagramIcon /></a>
                           <a href="https://www.facebook.com/roshnibano.roshnibano.1/photos_by"><FacebookIcon /></a>
                            <a href="https://twitter.com/abubakarazmi04"><TwitterIcon /></a>
                            <a href="https://www.linkedin.com/in/abu-bakar-7b9237206/"><LinkedInIcon /></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer