export const storydata=[
    {
        image:"https://assets.website-files.com/61228f5f1250b11665938f6a/6122b12a5ed8081807753dd1_1629663529913-image1-p-500.jpeg",
        title:"Finance",
        para:" English versions from the 1914 translation by H. Rackham",
        writer:"Michell scott"
    },
    {
        image:"https://assets.website-files.com/61228f5f1250b11665938f6a/6122b12a62dde0ae20cf676d_1629663529919-image9-p-1080.jpeg",
        title:"Finance",
        para:" English versions from the 1914 translation by H. Rackham",
        writer:"Michell scott"
    },
    {
        image:"https://assets.website-files.com/61228f5f1250b11665938f6a/6122b12a5c8d97e4e3e321ed_1629663529923-image17-p-1080.jpeg",
        title:"Finance",
        para:" English versions from the 1914 translation by H. Rackham",
        writer:"Michell scott"
    },
    {
        image:"https://assets.website-files.com/61228f5f1250b11665938f6a/6122b12aadcedb20221d5781_1629663529921-image19.jpg",
        title:"Finance",
        para:" English versions from the 1914 translation by H. Rackham",
        writer:"Michell scott"
    },
    {
        image:"https://assets.website-files.com/61228f5f1250b11665938f6a/6122b12aa1ab7b9a456493de_1629663529907-image14-p-500.jpeg",
        title:"Finance",
        para:" English versions from the 1914 translation by H. Rackham",
        writer:"Michell scott"
    },
    {
        image:"https://assets.website-files.com/61228f5f1250b11665938f6a/6122b12a5c8d9785a9e321ee_1629663529918-image16-p-500.jpeg",
        title:"Finance",
        para:" English versions from the 1914 translation by H. Rackham",
        writer:"Michell scott"
    },
]