import React from 'react'

const Email2 = () => {
    return (
        <>
            <div className="main bg-blue-800 py-16 w-full">
                <div className="in w-2/5 m-auto space-y-4 sym:w-full sym:px-3">
                    <h1 className='text-4xl text-white tracking-wide font-bold text-center small:text-3xl'>Get our weekly email</h1>
                    <p className='text-xl font-semibold tracking-wide text-white text-center small:text-sm'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum et lacus varius, dapibus leo eget.</p>
                    <div className="searh bg-white rounded-2xl  border flex items-center relative">
                        <input type="text" className='w-full py-3 px-5 outline-none rounded-2xl small:px-2 small:text-sm small:py-2' placeholder='Enter Your Email' />
                        <div className="icon bg-sky-500 py-3 px-12 cursor-pointer rounded-2xl small:py-2 small:px-8">
                            <span className='text-lg font-semibold text-white small:text-sm '>Send</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Email2