
import Emailimg from '../images/email.jpg';
import React, { useRef } from 'react';
// import emailjs from '@emailjs/browser';

const Email = () => {
    // const form=useRef();
    // const sendEmail = (e) => {
    //     e.preventDefault();
    
    //     emailjs.sendForm('service_z4beyn6', 'template_7fvb4tl', form.current, 'hoPlnqXwhcUCmtZ59')
    //       .then((result) => {
    //           console.log(result.text);
    //       }, (error) => {
    //           console.log(error.text);
    //       });
    //   };
    return (
        <div className="main bg-blue-800 w-full py-14">
            <div className="in w-3/4 m-auto flex space-x-5 items-center  small:w-full small:px-3">
                <div className="left flex-1 space-y-7">
                    <h1 className='text-4xl font-semibold tracking-wide text-white uppercase small:text-2xl'>A blog that people will actually want to read</h1>
                    <p className='text-xl leading-6 text-white small:text-sm'>People use Bulletin to share big ideas with the world on a
                        regular basis with a focus on accessibility and usability.</p>
                    <form className="searh bg-white rounded-2xl w-3/4 border flex items-center relative small:w-full sym:w-4/5">
                        <input type="text" className='w-full py-3 px-5 outline-none rounded-2xl small:px-2 small:py-2 small:text-sm' placeholder='Enter Your Email' />
                        <div className="icon bg-sky-500 py-3 px-12 cursor-pointer rounded-2xl small:py-2 small:px-8 ">
                            <span className='text-lg font-semibold text-white small:text-sm'>Send</span>
                        </div>
                    </form>
                </div>
                <div className="right flex-1 ssm:hidden">
                    <div className="img">
                        <img src={Emailimg} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Email