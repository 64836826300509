import React from 'react'
import Navbar from '../Component/Navbar'
import Footer from '../Component/Footer'
const Contect = () => {
    return (
        <>
        <Navbar/>
            <div className="main py-16 w-full bg-white small:py-10">
                <div className="in w-3/5 m-auto sym:w-full small:px-3">
                    <div className="content py-3 space-y-3 sym:px-16 small:px-0">
                        <h1 className='text-4xl font-bold tracking-wide text-center small:text-2xl'>Get in touch</h1>
                        <p className='text-xl text-center small:text-sm small:leading-6'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Vestibulum et lacus varius, dapibus leo eget, semper metus. Suspendisse egestas est mi.</p>
                    </div>
                    <div className="box flex flex-col space-y-5 w-3/5 m-auto pt-10 small:w-full">
                        <label htmlFor="" className='font-semibold'> What's Name</label>
                        <input type="text" className='border py-3 px-5 outline-none small:py-2 small:text-sm small:px-3'  placeholder='Whats Your Name'/>
                        <label htmlFor="">Your Mail</label>
                        <input type="text" className='border py-3 px-5 outline-none small:py-2 small:text-sm small:px-3'  placeholder='name@example.com'/>
                        <label htmlFor="">Send Massege</label>
                        <textarea name="" id="" cols="30" rows="8" className='border p-3 small:text-sm'></textarea>
                        <div className="btn ">
                            <button className='py-2 px-8 bg-sky-600 rounded-full text-white outline-none' placeholder='how can we help?'>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Contect