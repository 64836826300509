import React, { useState } from 'react'
import Logo from '../images/logo.png'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useEffect } from 'react';
const Navbar = () => {
    const [nav,setNav]=useState(false);
    const Handleclick=()=>{
      setNav(!nav); 
    }
const [btn,stbtn]=useState(false);
const Clickbtn=()=>{
    stbtn(!btn)
}
const [btn2,setBtn2]=useState(false);
const Clickbtn2=()=>{
    setBtn2(!btn2);
}
    return (
        <>
            <div className="navbar bg-white w-full py-10 shadow-lg">
                <div className="in-navbar w-3/4 flex items-center justify-between m-auto small:w-full small:px-3">
                    <div className="left flex items-center space-x-5 cursor-pointer">
                        <div  className="logo border rounded-full p-2 bg-slate-600">
                            <Link to="/">
                            <img src={Logo} alt="" className='w-10 small:w-6' />
                            </Link>
                        </div>
                        <Link to="/" className='text-3xl font-bold tracking-wide small:text-xl'><span className='text-cyan-600'>NEWS</span> DAILY</Link>
                    </div>
                    <div className="right flex items-center space-x-5 ssm:hidden">
                        <div className="pro cursor-pointer text-xl relative">
                            <span onClick={Clickbtn}>Products 
                            {
                                !btn?<ArrowDropUpIcon/>:<ArrowDropDownIcon/>
                            }
                               
                            </span>
                            <div className={!btn?'hover shadow-lg p-5 text-xl list-none space-y-3 absolute top-8 rounded-lg  z-70 bg-white left-0':'hidden'
                                
                        }>
                                <li><a href="">Finance</a></li>
                                <li><a href="">Business</a></li>
                                <li><a href="">Entertaiment</a></li>
                                <li><a href="">Sports</a></li>
                                <li><a href="">Travel</a></li>
                            </div>
                        </div>
                        {/* hover */}

                        {/* end hover */}
                        <div className="pro cursor-pointer text-xl relative">
                            <span onClick={Clickbtn2} >Ctategries{
                                !btn2?<ArrowDropUpIcon/>:<ArrowDropDownIcon/>
                                }
                                 </span>
                            <div className={!btn2?"hover shadow-lg p-5 text-xl list-none space-y-3 absolute top-8 rounded-lg z-70 bg-white left-0":"hidden"}>
                                <li><a href="">Finance</a></li>
                                <li><a href="">Business</a></li>
                                <li><a href="">Entertaiment</a></li>
                                <li>
                                    <Link to="contect">Contect</Link>
                                </li>
                                <li><a href="">Travel</a></li>
                            </div>
                        </div>
                        <div className="btn bg-cyan-600 py-2 px-8 rounded-full">
                            <button className='text-lg  font-semibold text-white'>
                                <Link to='suscribe'>
                                    Suscribe
                                </Link>
                            </button>
                        </div>

                    </div>
                    <div className="icon cursor-pointer lg:hidden " onClick={Handleclick}>
                        {!nav? <MenuIcon className='w-5' />:<CloseIcon className='w-5'/>

                        }
                       
                    </div>
                    <div className={!nav ?'hidden':'box absolute z-50 bg-white w-full py-10 flex flex-col items-center space-y-8 justify-center top-32 left-0 border'}>
                    <div className="pro cursor-pointer text-xl relative ">
                            <span>Products <ArrowDropDownIcon /></span>
                            <div className="hover shadow-lg p-5 text-xl list-none space-y-3 absolute top-8 
                            rounded-lg hidden z-70 bg-white left-0">
                                <li><a href="">Finance</a></li>
                                <li><a href="">Business</a></li>
                                <li><a href="">Entertaiment</a></li>
                                <li><a href="">Sports</a></li>
                                <li><a href="">Travel</a></li>
                            </div>
                        </div>
                        {/* hover */}

                        {/* end hover */}
                        <div className="pro cursor-pointer text-xl relative">
                            <span>Ctategries <ArrowDropDownIcon /></span>
                            <div className="hover shadow-lg p-5 text-xl list-none space-y-3 absolute top-8 
                            rounded-lg hidden z-70 bg-white left-0">
                                <li><a href="">Finance</a></li>
                                <li><a href="">Business</a></li>
                                <li><a href="">Entertaiment</a></li>
                                <li>
                                    <Link to="contect">Contect</Link>
                                </li>
                                <li><a href="">Travel</a></li>
                            </div>
                        </div>
                    <div className="btn bg-cyan-600 py-2 px-8 rounded-full">
                            <button className='text-lg  font-semibold text-white'>
                                <Link to='suscribe'>
                                    Suscribe
                                </Link>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Navbar