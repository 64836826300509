import React from 'react'
import Fetureimg from '../images/feture.jpeg'
import { feturedata } from './Feturedata'
const Feture = () => {
    const Boxdata=(data)=>{
        return(
            <div className="box flex items-center border-b py-5 hover:opacity-75 cursor-pointer ">
                <div className="left flex-1 px-3 space-y-3 small:space-y-2 small:px-0">
                    <span className='text-blue-400 text-xl font-semibold '>{data.title}</span>
                    <p className='text-lg font-semibold capitalize small:text-sm'>{data.para}</p>
                    <span className='text-lg text-gray-600 pt-3 small:text-sm'>{data.writer} . 8.22.21</span>
                </div>
                <div className="right flex-1 rounded-xl">
                    <img src={data.image} alt="" className='rounded-xl' />
                    <div className="content">
                    </div>
                </div>
            </div>
        )
    }
  return (
   <div className="main bg-white w-full py-24">
    <div className="in w-3/4 m-auto flex space-x-10 ssm:flex-col ssm:w-full ssm:px-8 ssm:space-x-0 sym:px-3">
        <div className="left flex-1 cursor-pointer hover:opacity-80">
            <div className="img rounded-xl bg-black ">
                <img src={Fetureimg} alt="" className='opacity-70' />
            </div>
            <div className="content space-y-5 pt-5">
                <span className='text-blue-400 font-semibold text-lg'>Sports</span>
                <h1 className='text-3xl font-bold tracking-wide leading-7 capitalize small:text-2xl'>7 of the best exapmle of beutiful design</h1>
                <p className='text-xl tracking-wide pb-4 small:text-lg'>Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic</p>
                <span className='font-semibold text-gray-600'>MICHEL SCOTT  . AUG 22, 2022</span>
            </div>
        </div>
        <div className="right flex-1 ssm:pt-6">
            <h1 className='text-xl font-semibold pb-3'>Fetures</h1>
            <div className="items space-y-5">
                {
                feturedata.map(Boxdata)
                }
            </div>
        </div>
    </div>
   </div>
  )
}

export default Feture