import React from 'react'
import Email from './Email'
import Email2 from './Email2'
import Feture from './Feture'
import Footer from './Footer'
import MoreStories from './MoreStories'
import Navbar from './Navbar'
import Story from './Story'
const Home = () => {
  return (
    <>
      <Navbar />
      <Email />
      <Feture/>
      <Story/>
      <MoreStories/>
      <Email2/>
      <Footer/>
    </>
  )
}

export default Home