import React from 'react'
import { storydata } from './Storydata'
const Story = () => {
    const Storydata=(data)=>{
        return(
            <div className="box cursor-pointer hover:opacity-75">
                <div className="image rounded-xl bg-black ssm:w-full">
                    <img src={data.image} alt="" className='rounded-xl opacity-70 w-full h-full object-cover ssm:w-full'/>
                </div>
                <div className="content pt-3">
                    <span className='text-lg text-blue-400 font-semibold'>{data.title}</span>
                    <p className='text-lg font-semibold tracking-wide leading-7 pb-2 capitalize'>{data.para}</p>
                    <span className='font-semibold text-gray-600'>{data.writer} . AUG 2021</span>
                </div>
            </div>
        )
    }
  return (
  <>
  <div className="main py-8 w-full bg-white">
    <div className="in w-3/4 m-auto ssm:w-full ssm:px-8 sym:px-3">
        <h1 className='text-2xl font-semibold'>Latest Story</h1>
        <div className="items grid grid-cols-3 gap-10 pt-4 ssm:flex ssm:flex-col ">
            {
                storydata.map(Storydata)
            }
        </div>
    </div>
  </div>
  </>
  )
}

export default Story