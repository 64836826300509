import React from 'react'
import Footer from '../Component/Footer'
import Navbar from '../Component/Navbar'

const Suscribe = () => {
  return (
    <>
  <Navbar/>
  <div className="main py-16 flex flex-col items-center justify-center bg-white small:py-8">
    <div className="in w-3/5 m-auto sym:w-full sym:px-8 small:px-0">
      <h1 className='text-4xl pt-4 font-bold text-center small:text-2xl small:pt-0'>About News Daily</h1>
      <p className='text-center pt-4 text-xl font-semibold'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Vestibulum et lacus varius, dapibus leo eget, semper metus. Suspendisse egestas est mi.</p>
        <div className="box bg-blue-800 py-20 mt-16  text-white sym:px-2">
          <div className="in-box flex flex-col justify-center items-center space-y-4">
            <h1 className='text-4xl font-bold small:text-2xl'>Get our weekly email</h1>
            <p className='text-lg text-center small:text-sm'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
              Vestibulum et lacus varius, dapibus leo eget.</p>
              <div className="searh bg-white rounded-2xl  border flex items-center relative ">
                        <input type="text" className='w-full py-3 px-5 outline-none rounded-2xl text-black small:px-2 small:py-2 small:text-sm' placeholder='Enter Your Email' />
                        <div className="icon bg-sky-500 py-3 px-10 cursor-pointer rounded-2xl small:px-8 small:py-2">
                            <span className='text-lg font-semibold text-white small:text-sm'>Send</span>
                        </div>
                    </div>
          </div>
        </div>
        <div className="para pt-10 space-y-3 small:px-3">
          <h1 className='text-4xl font-bold pb-2 small:text-2xl'>What’s a Rich Text element?</h1>
          <p className='text-lg pb-3 small:text-sm small:leading-6'>The rich text element allows you to create and format headings, paragraphs, blockquotes, images, and video all in
             one place instead of having to add and format them individually. Just double-click and easily create content.</p>
             <span className='text-xl font-semibold '>Static and dynamic content editing</span>
             <p className='text-lg pb-3 small:text-sm small:leading-6'>A rich text element can be used with static or dynamic content. For static content, just drop it into any page and begin editing. For dynamic content, add a rich text field to 
              any collection and then connect a rich text element to that field in the settings panel. Voila!</p>
              <span className='text-xl font-semibold '>How to customize formatting for each rich text</span>
              <p className='text-lg pb-2 small:text-sm small:leading-6'>Headings, paragraphs, blockquotes, figures, images, and figure captions can all be styled after
                 a class is added to the rich text element using the "When inside of" nested selector system.</p>
        </div>
    </div>
  </div>
  <Footer/>
    </>
  )
}

export default Suscribe